<template>
  <!-- partial -->
  <!-- partial:partials/_sidebar.html -->
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item">
        <router-link class="nav-link" to="/">
          <i class="icon-grid menu-icon"></i>
          <span class="menu-title">Inicio</span>
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
          <i class="icon-layout menu-icon"></i>
          <span class="menu-title">Conteudo</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="ui-basic">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"><router-link class="nav-link" to="/atividades">Atividades</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/videos">Vídeos</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/tutoriais">Tutoriais</router-link></li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#form-elements-lab" aria-expanded="false"
          aria-controls="form-elements-lab">
          <i class="icon-columns menu-icon"></i>
          <span class="menu-title">Laboratório</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="form-elements-lab">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"><router-link class="nav-link" to="/agua">Água</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/ar">Ar</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/terra">Terra</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/fogo">Fogo</router-link></li>
          </ul>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#biblioteca" aria-expanded="false" aria-controls="biblioteca">
          <i class="icon-bar-graph menu-icon"></i>
          <span class="menu-title">Biblioteca</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="biblioteca">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"><router-link class="nav-link" to="/biblioteca">Material</router-link></li>
          </ul>
        </div>
      </li>
      <li v-if="this.acesso.possuiAcesso != 'isLeitura'" class="nav-item">
        <a class="nav-link" data-toggle="collapse" href="#form-elements-post" aria-expanded="false"
          aria-controls="form-elements-post">
          <i class="icon-grid-2 menu-icon"></i>
          <span class="menu-title">Postagem</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="collapse" id="form-elements-post">
          <ul class="nav flex-column sub-menu">
            <li class="nav-item"><router-link class="nav-link" to="/criar-feed">Feed</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/criar-post">Post</router-link></li>
            <li class="nav-item"><router-link class="nav-link" to="/noticias">Notícias</router-link></li>
          </ul>
        </div>
      </li>

    </ul>
  </nav>

</template>

<script>
import usuario from '@/store/modules/usuario';
import apiMethods from '../../views/services/homeService';
import Acesso from '../../models/controleAcesso'
export default {
  name: 'SideBar',

  data() {
    return {
      acesso: new Acesso(),
      usuario: {},
    }
  },
 async mounted() {
    const usuarioData = sessionStorage.getItem('usuario');
    if (usuarioData) {
      this.usuario = JSON.parse(usuarioData);
      if (this.usuario.id_usuario) {
        //console.log("this usuarios", this.usuario.id_usuario);
        this.verificaPerfil(this.usuario.id_usuario);
      } else {
        console.error("ID de usuário não encontrado nos dados.");
      }
    } else {
      console.error("Nenhum dado de usuário encontrado no sessionStorage.");
    }
    if(this.usuario){
            await this.validarAcessos(this.usuario.perfil);
        }
  },

  methods: {
    async validarAcessos() {
            this.acesso = new Acesso(this.usuario.perfil);
            //console.log("acesso", this.acesso.possuiAcesso);
        },
    async verificaPerfil(id) {
      const dados = await apiMethods.verificaPerfil(id)
      console.log("dados verifica perfil SideBar0", dados.length);
      if (dados.length <= 0) {
        //console.log("dentro if");

        this.$router.push("/perfil"); // Redirecionar para tela perfil
      }
    }
  }
}


</script>