import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store/index';
import 'devextreme/dist/css/dx.light.css';
import { locale, loadMessages } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';
import "quill/dist/quill.snow.css";

loadMessages(ptMessages);
locale('pt');
const app = createApp(App);

let user = sessionStorage.getItem('token');

if(user !== null){
    router.push('/');
  } else {
  console.log("não logado");
  // Usuário não autenticado
  // Faça algo, como redirecionar para a página de login
  app.config.globalProperties.$user = null;
  router.push('/login');
}

app.use(router);
app.use(store);
app.mount('#app');