const apiClient = require('../../validador/apiClient');
const store = require('../../store/index').default;
const axios = require('axios');
const apiMethods = {

  async usuarios() {
    try {
      const response = await apiClient.get('/usuarios');
      return response.data;
    } catch (error) {
      console.error('Erro ao obter usuarios:', error);
      throw error;
    }
  },

  async login(email, password) {
    const user = {
      email,
      password
    };
    try {
      const response = await apiClient.post('/login', user);
      if (response.data.token) {
        console.log("logi usuario", response);
        
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('user', response.data.nome);
        sessionStorage.setItem('usuario', JSON.stringify(response.data));
        store.commit('usuario/setUsuario')

        apiClient.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`; // Atualiza o token após o login
        return response.data;
      } else {
        return response.data.status;
      }
    } catch (error) {
      console.error('Erro ao login:', error.response.data);
      throw error;
    }
  },

  async verificaPerfil(id) {
    //console.log("verifica id service", id);
    
    try {
      const response = await axios.get(`https://apienerge.apololab.net:5000/verificaPerfil/${id}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter verificaPerfil:', error);
      throw error;
    }
  },

  async gravaFeed(dados) {

    //console.log("grava gravaFeed", dados);

    const formData = new FormData();

    formData.append('id_atividade', dados.id_atividade);
    formData.append('id_ordem', dados.id_ordem);
    formData.append('usuario', dados.usuario);
    formData.append('titulo', dados.titulo);
    formData.append('url', dados.url);
    formData.append('texto', dados.texto);
    formData.append('link', dados.link);
    if (dados.selectedFile) {
      formData.append('file', dados.selectedFile);
    }
    formData.append('imageName', dados.imageName);
    formData.append('tag', dados.tag);

    try {
      const response = await axios.post('https://apienerge.apololab.net:5000/atividades/gravaFeed', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    } catch (error) {
      console.error('Erro ao gravaFeed:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  async obterFeed() {
    //console.log("obterFeed -");

    try {
      let response = []
      response = await axios.get(`https://apienerge.apololab.net:5000/atividades/obterFeed`);
      //console.log("response.data", response);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter obterFeed:', error);
      throw error;
    }
  },


  async gravaPost(dados) {

    //console.log("grava gravaPost", dados);

    const formData = new FormData();

    formData.append('id_atividade', dados.id_atividade);
    formData.append('id_ordem', dados.id_ordem);
    formData.append('usuario', dados.usuario);
    formData.append('titulo', dados.titulo);
    formData.append('texto', dados.texto);
    formData.append('tag', dados.tag);
    try {
      const response = await axios.post('https://apienerge.apololab.net:5000/atividades/gravaPost', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response;
    } catch (error) {
      console.error('Erro ao gravaPost:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  async obterPost() {
    //console.log("obterFeed -");
    try {
      let response = []
      response = await axios.get(`https://apienerge.apololab.net:5000/atividades/obterPost`);
      //console.log("response.data", response);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter obterFeed:', error);
      throw error;
    }
  },


  async gravaPerfil(dados){
    try {
      const response = await axios.post('https://apienerge.apololab.net:5000/gravaPerfil', dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar gravaPerfil:', error);
      throw error;
    }
  },


  async updatePerfil(dados){
    try {
      const response = await axios.post('https://apienerge.apololab.net:5000/updatePerfil', dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar updatePerfil:', error);
      throw error;
    }
  },

  async criaUsuarios(dados) {
    try {
      const response = await apiClient.post('/createUsers', dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar usuário:', error);
      throw error;
    }
  },

  async validation(token) {
    try {
      const response = await apiClient.get(`/validation/${token}`);
      return response.data;
    } catch (error) {
      //console.log('Erro ao validation:', error);
      throw error;
    }
  },

  async alteraSenha(dados) {
    try {
      const response = await apiClient.post('/alterasenha', dados);
      return response.data;
    } catch (error) {
      //console.log('Erro ao alterar senha:', error);
      throw error;
    }
  },

  async editaUsuario(dados) {
    try {
      const response = await apiClient.post('/editarUsuario', dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao editar usuário:', error);
      throw error;
    }
  },

  async deleteUsuario(dados) {
    try {
      const response = await apiClient.post('/deleteUsuario', dados);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar usuário:', error);
      throw error;
    }
  },

  async estados(){
    https://servicodados.ibge.gov.br/api/v1/localidades/municipios
    try {
      let response = []
      response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`);
      ////console.log("response.cidade", response);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter cidade:', error);
      throw error;
    }
  },

  async cidades(UF){
    https://servicodados.ibge.gov.br/api/v1/localidades/municipios
    try {
      let response = []
      response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/distritos`);
      ////console.log("response.cidade", response);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter cidade:', error);
      throw error;
    }
  },


  async obterCampanha() {
    //console.log("obterFeed -");
    try {
      let response = []
      response = await axios.get(`https://apienerge.apololab.net:5000/campanha/obterCampanha`);
    console.log("response.data", response);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter obterCampanha:', error);
      throw error;
    }
  },




  
  

  // Adicione mais métodos conforme necessário
};

module.exports = apiMethods;