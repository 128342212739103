export default class Acesso {
  constructor(objAcesso) {

    //console.log("acesso js", objAcesso);
    

    if (!objAcesso) {
      return;
    }

 
    if(objAcesso === 'isAdmin'){
      this._ehAdmin = objAcesso
    }

    if(objAcesso === 'isModificar') {
      this._escrita = objAcesso;
    }

    if(objAcesso === 'isLeitura') {
      this._leitura = objAcesso
    }

  }

  get leitura() {
    return this._leitura || false;
  }

  get escrita() {
    return this._escrita || false;
  }

  get ehAdmin() {
    return this._ehAdmin || false;
  }

  get possuiAcesso() {
    return this.ehAdmin || this.leitura || this.escrita;
  }
}
