<template>
    <div class="card">
        <div v-if="isVisible" class="modal" @animationend="handleAnimationEnd">
            <div class="modal-content" :class="{ 'slide-out': isClosing }">
                <img src="../../public/img/cam1.jpg">
                <div class="modal-footer" style="background-color: #A4D233;">
                    <button type="button" class="btn btn-primary" @click="hide">Fechar</button>
                    <button type="submit" class="btn btn-primary">Saiba mais !!</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import apiMethods from '../views/services/homeService';
import Swal from 'sweetalert2';
export default {
    name: 'ModalPerfil',
    setup() {
        const router = useRouter();
        const isVisible = ref(false);
        const isClosing = ref(false);
        const usuario = ref({
            nome: '',
            email: '',
            telefone: '',
            dt_nascimento: '',
            cpf: '',
            empresa: '',
            status: '',
        });

        const show = (data) => {
            isClosing.value = false;
            isVisible.value = true;

        };

        const hide = () => {
            isClosing.value = true;
        };

        const handleAnimationEnd = () => {
            if (isClosing.value) {
                isVisible.value = false;
                isClosing.value = false; // Reset isClosing
            }
        };

        const salvarEditar = async () => {
            //console.log("salvar");

            if (
                usuario.value.nome !== '' &&
                usuario.value.email !== '' &&
                usuario.value.empresa !== '' &&
                usuario.value.status !== ''
            ) {
                let salvar = await apiMethods.editaUsuario(usuario.value);

                if (salvar) {
                    Swal.fire({
                        icon: "success",
                        title: "Sucesso",
                        text: "Dados Salvos Com Sucesso !!",
                    }).then(() => {
                        location.reload(); // Atualiza a página após clicar "OK"
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Ops! Erro ao Salvar",
                        text: "Verifique se Todos os Campos Estão preenchidos !!",
                    });
                }
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Ops! Erro ao Salvar",
                    text: "Verifique se Todos os Campos Estão preenchidos !!",
                });
            }
        };

        return {
            isVisible,
            isClosing,
            usuario,
            show,
            hide,
            handleAnimationEnd,
            salvarEditar,
        };
    },
};
</script>

<style scoped>
.modal {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    /* background-color: rgba(0, 0, 0, 0.4); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    width: 50%;
    /* height: 70%; */
    /* background-color: rgb(65, 64, 64); */
    box-sizing: border-box;
    animation: slide-in 0.5s forwards;
    display: flex;
    flex-direction: column;
}

@keyframes slide-in {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.slide-out {
    animation: slide-out 0.5s forwards;
}

@keyframes slide-out {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}

.btn-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #ffffff;
    cursor: pointer;
}

.btn-close:hover {
    color: #ff0000;
}

.modal-header,
.modal-body {
    padding: 20px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
}

.modal-body {
    flex: 1;
    overflow-y: auto;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}

.modal-footer button {
    margin-left: 10px;
}
</style>
